<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#098064"
            small
            class="mx-2"
            v-bind="attrs"
            style="color: white"
            v-on="on"
            @click="dialogModal(true, 'create','call')"
          >
            تصدير
            <v-icon
              right
            >
              mdi-file-excel
            </v-icon>
          </v-btn>
        </template>
        <span>  تصدير  الاشخاص والمركبات  </span>
      </v-tooltip>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="customEntries"
      :options.sync="options"
      show-expand
      :server-items-length="customEntriesTotal"
      :loading="loading"
      :disable-filtering="true"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions:[10, 20, 50],
        itemsPerPageText: $t('dataTable.itemsPerPageText'),
        pageText: $t('dataTable.pageText')
      }"
      :hide-default-header="!$vuetify.breakpoint.mobile"
      @update:page="fetch"
      @update:items-per-page="fetch"
    >
      <template v-if="!$vuetify.breakpoint.mobile" v-slot:header="{ props }">
        <datatable-header :props="props" :options.sync="options" :translation-key="translationKey" @update:filters="fetch"></datatable-header>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    الاسم
                  </th>
                  <th>
                    اسم المعرف
                  </th>
                  <th>
                    رقم السيارة
                  </th>
                  <th>
                    نوع السيارة
                  </th>
                  <th>
                    تاريخ انتهاء السيارة
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="data in item.cars" :key="data.id">
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    {{ item.identifier_name }}
                  </td>
                  <td>
                    {{ data.number }}
                  </td>
                  <td>
                    {{ data.type }}
                  </td>
                  <td>
                    <v-chip
                      v-if="data.expire_date"
                      :color="Date.now() > new Date(data.expire_date) ? 'error': ''"
                      label
                      small
                    >
                      {{ data.expire_date | format_date }}
                    </v-chip>
                    <v-chip
                      v-else
                      label
                      small
                    >
                      لا يوجد
                    </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
      <template v-slot:item.name="{ item }">
        <v-chip
          label
          color="black"
          small
          dark
        >
          {{ item.name }}
        </v-chip>
      </template>

      <template v-slot:item.user.name="{ item }">
        {{ item.user ? item.user.name : '' }}
      </template>
      <template v-slot:item.checkpoint.name="{ item }">
        {{ item.checkpoint.name }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | format_date }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          v-can="'read_cars'"
          class="mx-2"
          tile
          small
          color="secondary"
          @click="showImages(item.id)"
        >
          <v-icon left>
            mdi-folder-multiple-image
          </v-icon>
          صور العجلات
        </v-btn>
      </template>
    </v-data-table>
    <export-dialog
      :dialog-modal="dialogModal"
      :data="dialogData"
      :translation-key="translationKey"
      @update:table="fetch"
    ></export-dialog>
  </v-card>
</template>

<script>
import axios from '@axios'
import DatatableHeader from '@/components/dataTables/DatatableHeader.vue'
import ExportDialog from '@/views/pages/statistics/export.vue'

import { ObjectToQuery } from '@/plugins/helper';
import { getFilters, getOptions } from '@/components/dataTables/helper'
import router from '@/router'

export default {
  name: 'Checkpoints',
  components: { DatatableHeader, ExportDialog },
  data: () => ({
    translationKey: 'pages.enterByCall',
    filters: false,
    customEntries: [],
    customEntriesTotal: 0,
    loading: true,
    options: {
      sortBy: ['created_at'],
      sortDesc: [true],
    },
    tempOptions: '',
    type: 'call',
    search: '',
    dialogData: {
      dialog: false,
      params: {},
      type: 'details',
      export_type: 'call',
    },
    headers: [
      {
        text: 'name',
        align: 'center',
        sortable: false,
        value: 'name',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },

      {
        text: 'checkpoint',
        value: 'checkpoint.name',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'select',
          api: async $axios => {
            const check = await $axios.get('/checkpoints')

            return check.data.data.map(checkpoint => checkpoint.name)
          },
          options: [],
        },
      },
      {
        text: 'username',
        value: 'user.name',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'created_at',
        value: 'created_at',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'date',
          methods: ['between', 'greater', 'less', 'equal', 'notEqual'],
        },
      },
      {
        text: 'actions',
        value: 'actions',
        align: 'center',
      },
    ],
  }),
  setup() {
    return {

    }
  },
  mounted() {
    this.options = getOptions(this.$route, this.options);
    this.fetch();
  },

  async export_all() {
    axios
      .get('customEntries/export', {
        params: {
          from_date: '2022-06-01',
          to_date: '2023-01-01',
          type: 'call',
        },
        responseType: 'arraybuffer',
      })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = window.URL.createObjectURL(blob)
        window.open(url)
      })
  },
  methods: {
    async showImages($id) {
      await router.push(`/statistics/images/${$id}`);
    },
    async fetch() {
      if (JSON.stringify(this.options) !== this.tempOptions) {
        this.tempOptions = JSON.stringify(this.options);
        await this.$router.replace({ query: this.options })
      }
      window.scrollTo(0, 0);
      localStorage.setItem(`itemsPerPage_${this.$route.name}`, this.options.itemsPerPage);
      this.loading = true;
      const filters = getFilters(this.headers);
      const query = ObjectToQuery({
        ...this.options,
        type: this.type,
        filters,
      })
      const response = await axios.get(`/customEntries/dt?${query}`);
      this.customEntries = response.data.data.data
      this.customEntriesTotal = response.data.data.total
      this.loading = false
    },
    // eslint-disable-next-line camelcase
    dialogModal(dialog, type = this.dialogData.type, export_type) {
      this.dialogData.dialog = dialog
      this.dialogData.type = type
      const filters = getFilters(this.headers);
      this.dialogData.params = {
        type: this.type,
        filters,
      }
      // eslint-disable-next-line camelcase
      this.dialogData.export_type = export_type
    },
    $(key) {
      return this.$t(`${this.translationKey}.${key}`);
    },
  },

}
</script>
